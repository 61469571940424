import api from "@axios";
import store from '@/store/index.js'
import Vue from 'vue'

class Auth {
    // Register
    static async register(data) {
        await api.post('/auth/register', data)
    }
    // Login
    static async login(data) {
        const { data: resData } = await api.post('/auth/login', data)
        this.storeToken(resData.token)
    }
    // Login with provider
    static async loginWith(provider) {
        try {
            const { data } = await api.get(`/auth/${provider}/redirect`)
            location.href = data.link;
        } catch (e) {
            Vue.prototype.$swal({
                icon: 'error',
                title: 'Oops...',
                text: 'Something went wrong!',
                customClass: {
                    confirmButton: 'btn btn-primary',
                },
                buttonsStyling: false,
            })
        }
    }
    static async loginWithCallback(link) {
        const { data } = await api.get(link);
        this.storeToken(data.token)
    }
    // Fetch user details
    static async fetch() {
        const user = store.state.user.user
        if (user) return user;
        if (!localStorage.getItem('token')) return null;
        try {
            const { data } = await api.get('/auth/user')
            store.commit('SET_USER_DATA', data)
          //  console.log('user', data);
        } catch {
            localStorage.removeItem('token');
            store.commit('LOGOUT');
            console.log('failed to fetch the user');
        }
        return store.state.user.user
    }
    // Verify email
    static async verifyEmail(token) {
        await api.post('/auth/verify', null, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        });
    }
    // Password
    static async sendResetPass(email) {
        return await api.post('/auth/reset-pass', { email })
    }
    static async updatePass(token, password) {
        await api.post('/auth/update-pass', { password }, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        })
    }
    // Store Token
    static storeToken(token) {
        localStorage.setItem("token", token);
        window.location.reload();
    }
    // Logout
    static async logout() {
        await api.post("/auth/logout")
        localStorage.removeItem("token")
        window.location.reload();
    }
}

export default Auth;