import Vue from 'vue'
import router from '@/router/index'
import store from '@/store/index.js'
// axios
import axios from 'axios'


const setHeaders = () => {
  const token = localStorage.getItem('token')
  return token ? {
    headers: {
      Authorization: `Bearer ${token}`
    }
  } : {}
}
console.log('Server Connected:', process.env.VUE_APP_SERVER_URL);
const server = axios.create({
  baseURL: `${process.env.VUE_APP_SERVER_URL}/api`,
  ...setHeaders()
})

// Add a response interceptor
server.interceptors.response.use(function (response) {
  return response;
}, function (error) {
  const status = error.response.status;
  if(status == 401) {
    store.commit('LOGOUT');
    router.push({ name: "login" }).catch(() => {})
  }
  return Promise.reject(error);
});

Vue.prototype.$http = server
export default server
