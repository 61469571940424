const pages = [
	// Home Page
	{
		path: '/',
		name: 'home-admin',
		component: () => import('@/views/admin/Home.vue'),
		meta: {
			pageTitle: 'Home',
			breadcrumb: [
				{
					text: 'Home',
					active: true
				}
			]
		}
	},
	// Manage all users
	{
		// All users in table
		path: '/user',
		name: 'users',
		component: () => import('@/views/admin/users/ListUsers.vue'),
		meta: {
			pageTitle: 'All Users'
		}
	},
	{
		// Order history for user
		path: '/user/:user_id/orders',
		name: 'user-order',
		component: () => import('@/views/admin/users/ListOrders.vue'),
		meta: {
			pageTitle: 'Orders'
		}
	},
	{
		// Specific prices for services
		path: '/user/:user_id/price',
		name: 'user-price',
		component: () => import('@/views/admin/users/ManagePrices.vue'),
		meta: {
			pageTitle: 'Specific Prices'
		}
	},
	{
		// Invoices for user
		path: '/user/:user_id/invoices',
		name: 'user-invoice',
		component: () => import('@/views/admin/users/invoices/ListInvoices.vue'),
		meta: {
			pageTitle: 'Invoices'
		}
	},
	// Manage all services
	{
		path: '/services',
		name: 'services',
		component: () => import('@/views/admin/services/ListServices.vue'),
		meta: {
			pageTitle: 'All Services'
		}
	},

// Vouchers
{
	path: '/vouchers',
	name: 'vouchers',
	component: () => import('@/views/admin/vouchers/Vouchers.vue'),
	meta: {
		pageTitle: 'Manage Vouchers'
	  }
   },

	// REPORTS
	// View all invoices
	{
		path: '/invoices',
		name: 'invoices',
		component: () => import('@/views/admin/reports/Invoices.vue'),
		meta: {
			pageTitle: 'All Invoices'
		}
	},
	// All Orders History
	{
		path: '/orders',
		name: 'all-orders',
		component: () => import('@/views/admin/reports/Orders.vue'),
		meta: {
			pageTitle: 'All Orders'
		}
	},
	// Manage Notifications
	{
		path: '/notification',
		name: 'notification',
		component: () => import('@/views/admin/notification/Index.vue'),
		meta: {
			pageTitle: 'Manage Notification'
		}
	},
	{
		path: '/settings',
		name: 'user-settings-admin',
		component: () => import('@/views/global/SettingPage.vue'),
		meta: {
			pageTitle: 'Settings'
		}
	}
];

// Add meta auth-admin, add prefix path /admin
const adminPages = pages.map((page) => ({
	...page,
	path: `/admin${page.path}`,
	meta: {
		...page.meta,
		auth: 'admin'
	}
}));
export default adminPages;
