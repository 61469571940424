<template>
  <b-alert
      v-model="isOffline"
      v-height-fade
      variant="error"
      dismissible
      class="alert-auto alert--offline"
    >
      <span>NO INTERNET</span>
  </b-alert>
</template>

<script>
import { BAlert } from "bootstrap-vue";
import { heightFade } from "@core/directives/animations";
export default {
  name: "AlertOffline",
  components: { BAlert },
  data: () => ({
    isOffline: false,
  }),
  directives: { "height-fade": heightFade },
  mounted() {
    window.addEventListener('offline', this.onOffline);
    window.addEventListener('online', this.onOnline);
  },
  beforeDestroy() {
    window.removeEventListener('offline', this.onOffline);
    window.removeEventListener('online', this.onOnline);
  },
  methods: {
    onOffline() {
      this.isOffline = true;
    },
    onOnline() {
      this.isOffline = false;
    },
  }
};
</script>

<style lang="scss" scoped>
.alert-auto {
  max-height: initial !important;
}
.alert--offline {
  padding: 13px;
  text-align: center;
  border: 2px solid rgb(234,84,85);
  position: fixed;
  z-index: 99999;
  width: 100%;
  background: rgba(234, 84, 85, 0.12);
  backdrop-filter: blur(4px);

  > span {
    color: rgb(234,84,85);
  }
}
</style>
