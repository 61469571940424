const pages = [
	// Home Page
	{
		path: '/',
		name: 'home',
		component: () => import('@/views/user/home/Home.vue'),
		meta: {
			pageTitle: 'Home',
			breadcrumb: [
				{
					text: 'Home',
					active: true
				}
			]
		}
	},
	// IMEI checker page
	{
		path: '/imei',
		name: 'imei-check',
		component: () => import('@/views/user/imei/Check.vue'),
		meta: {
			pageTitle: 'IMEI check'
		}
	},
	// IMEI Single page
	{
		path: '/imei/process/single',
		name: 'imei-process-single',
		component: () => import('@/views/user/imei/process/Single.vue'),
		meta: {
			pageTitle: 'Process Single IMEI'
		}
	},
	// IMEI Bulk/Multi page
	{
		path: '/imei/process/multi',
		name: 'imei-process-multi',
		component: () => import('@/views/user/imei/process/Bulk.vue'),
		meta: {
			pageTitle: 'Process Multi IMEI'
		}
	},
	// Order history page
	{
		path: '/orders',
		name: 'orders',
		component: () => import('@/views/user/OrderHistory.vue'),
		meta: {
			pageTitle: 'Order Histroy'
		}
	},
	// Services page
	{
		path: '/advanced-history',
		name: 'advanced-history',
		component: () => import('@/views/user/advanced-history/index.vue'),
		meta: {
			pageTitle: 'Services'
		}
	},
	// Invoices
	{
		path: '/invoices',
		name: 'invoices-history',
		component: () => import('@/views/user/Invoices.vue'),
		meta: {
			pageTitle: 'Invoices'
		}
	},
	// Deposit
	{
		path: '/deposit',
		name: 'deposit',
		component: () => import('@/views/user/deposit/Deposit.vue'),
		meta: {
			pageTitle: 'Deposit'
		}
	},
	// User Settings
	{
		path: '/settings',
		name: 'user-settings',
		component: () => import('@/views/global/SettingPage.vue'),
		meta: {
			pageTitle: 'Settings'
		}
	},
	// API Manage
	{
		path: '/api-manage',
		name: 'api-manage',
		component: () => import('@/views/user/api-manage/Index.vue'),
		meta: {
			pageTitle: 'API Manage'
		}
	}
];

// Add meta auth-user, add prefix path /user
const userPages = pages.map((page) => ({
	...page,
	path: `/user${page.path}`,
	meta: {
		...page.meta,
		auth: 'user'
	}
}));
export default userPages;
